import { createClient } from "@supabase/supabase-js";

// const supabase = createClient(
//   process.env.SUPABASE_URL ?? "",
//   process.env.SUPABASE_ANON_KEY ?? ""
// );

const supabase = createClient(
  "https://ealmujmxbmhjvahkdbyo.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVhbG11am14Ym1oanZhaGtkYnlvIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTkyNTA2NDEsImV4cCI6MTk3NDgyNjY0MX0.R_P5n9r2ectbydnPjhKUug59Zpi3jdvNpb7XLEfm5p4"
);

export default supabase;
