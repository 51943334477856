import { DoubleRightOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import CustomLanguageSelector from "./CustomLanguageSelector";
import AppLogo from "../assets/images/ryong-logo.png";

export default function WelcomePage({ notice }: any) {
  const classes = useStyle();

  return (
    <div className={classes.welcomePage}>
      <div className={classes.welcomePageInner}>
        <Typography.Title level={3}>HERZLICH WILLKOMMEN</Typography.Title>

        <img src={AppLogo} className={classes.ryongLogo} />
        <Typography.Title level={4}>RYONG KONZEPT</Typography.Title>
        <Typography.Paragraph className={classes.ryongDescription}>
          Ryong ist ein rein veganes Restaurant, das sich auf experimentelle und
          verfeinerte Streetfood-Gerichte spezialisiert hat. Durch Analyse und
          Verfeinerung von Rezepten aus der japanischen und vietnamesischen
          Küche bietet Ryong eine Reihe von Gerichten an, darunter frische
          Dinkel- und Phonudeln, Bao-Burger mit einer knackigen Tempura-Kruste
          und Bento-Boxen, die mit einer sorgfältigen Auswahl an Gemüse und
          Kräutern gefüllt sind.
        </Typography.Paragraph>

        <Typography.Paragraph className={classes.noticeText}>
          {notice}
        </Typography.Paragraph>

        <CustomLanguageSelector />

        <div id="google_translate_element"></div>

        <div className={classes.doubleArrowIcon}>
          <DoubleRightOutlined rotate={90} />
        </div>
      </div>

      <div className={classes.swipeUp}>
        <DoubleRightOutlined rotate={-90} />
      </div>
    </div>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  welcomePage: {
    position: "relative",
    height: "100vh",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    background: "#202020",
    "& .ant-typography": {
      color: colors.primary,
    },
    "& div.ant-typography": {
      color: colors.light100,
    },
  },
  noticeText: {
    color: colors.primary + " !important",
  },
  "@keyframes upAndDown": {
    "0%": { transform: "translate(0,0)" },
    "50%": { transform: "translate(0,20px)" },
    "100%": { transform: "translate(0,0)" },
  },
  doubleArrowIcon: {
    paddingTop: 5,
    animation: "2s $upAndDown infinite ease-in-out",
    "& .anticon": {
      fontSize: 42,
      color: colors.primary,
    },
  },
  welcomePageInner: {
    width: "100%",
    maxWidth: 700,

    padding: [0, 30],
    position: "absolute",
    top: "35%",
    left: "50%",
    transform: "translate(-50%,-35%)",
  },
  swipeUp: {
    height: 60,
    paddingTop: 5,
    width: "100vw",
    maxWidth: 500,
    position: "absolute",
    bottom: 0,
    background: "#111",
    // borderTopLeftRadius: 20,
    // borderTopRightRadius: 20,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .anticon": {
      fontSize: 28,
    },
  },
  ryongLogo: {
    width: "60%",
    maxWidth: 180,
    margin: [20, 0],
  },
  ryongDescription: {
    margin: [20, 0],
    color: colors.light100 + " !important",
  },
  socialBtns: {
    marginTop: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  socialBtn: {
    margin: 10,
    border: "none",
    background: colors.primary,
    borderRadius: 5,
    "& .anticon": {
      color: colors.light100 + " !important",
    },
  },
}));
